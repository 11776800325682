import { useState } from 'react';
import moment from 'moment';
import { Button, Form, Input, Select } from 'antd';
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import './style.scss';
import {
  formattedLocationOptions,
  formattedMachinesByLocationOptions,
  formattedUserByRoleOptions
} from 'utils/formatters';
import { useStoreInContext } from 'store/main';
import { formattedStatusOptions, statusList } from '..';
import MyDatePicker from 'components/MomentDatePicker';

const { TextArea } = Input;

function TaskForm({ itemData, action, setDrawerOpen }) {
  const [selectedLocationId, setSelectedLocationId] = useState();
  const { locations, machines, users } = useStoreInContext(state => state);
  const locationsOptions = formattedLocationOptions(locations);
  const techniciansOptions = formattedUserByRoleOptions(users, '3');
  const machineOptions = formattedMachinesByLocationOptions(selectedLocationId, machines);
  const statusOptions = formattedStatusOptions(statusList);

  const onFinish = (values) => {
    console.log('Success:', values);
    action(values);
    return setDrawerOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="NewTasks">
      <div className="new-tasks-form">
        <Form
          layout="vertical"
          name="new-tasks-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{
            id: itemData?.id || undefined,
            locationId: itemData?.locationId || undefined,
            machineId: itemData?.machineId || undefined,
            userId: itemData?.userId || undefined,
            date: itemData?.date ? moment(itemData?.date, 'YYYY-MM-DD') : undefined,
            title: itemData?.title || undefined,
            status: itemData?.status || 'toDo',
            description: itemData?.description || undefined,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Task ID"
            name="id"
            rules={[{ required: true, message: 'Please input the task ID!' }]}
          >
            <Input placeholder="Insert the name" />
          </Form.Item>
          <Form.Item
            label="Task title"
            name="title"
            rules={[{ required: true, message: 'Please input the task title!' }]}
          >
            <Input placeholder="Insert the name" />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="date"
            rules={[{ required: true, message: 'Please input the date!' }]}
          >
            <MyDatePicker
              format={{
                format: 'YYYY-MM-DD',
                type: 'mask',
              }}
            />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Please input the status!' }]}
          >
            <Select options={statusOptions} placeholder="Insert the status" />
          </Form.Item>
          <Form.Item
            label="Task Description"
            name="description"
            rules={[{ required: true, message: 'Please input the description!' }]}
          >
            <TextArea
              placeholder="Insert the description"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item
            label="Responsible"
            name="userId"
            rules={[{ required: true, message: 'Please input the responsible!' }]}
          >
            <Select options={techniciansOptions} placeholder="Insert the responsible" />
          </Form.Item>
          <Form.Item
            label="Location"
            name="locationId"
            rules={[{ required: true, message: 'Please input the location!' }]}
          >
            <Select
              options={locationsOptions}
              placeholder="Insert the location"
              onSelect={(value) => setSelectedLocationId(value)}
            />
          </Form.Item>
          <Form.Item
            label="Machine"
            name="machineId"
            rules={[{ required: true, message: 'Please input the machine!' }]}
          >
            <Select
              disabled={!selectedLocationId}
              options={machineOptions}
              placeholder="Insert the machine"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {itemData ? 'Update Task' : 'Create task'}
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default TaskForm;
