// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.ProfitChart {
  position: relative;
  z-index: 1;
}
.ProfitChart .title {
  font-weight: 600;
  font-size: 1.1em;
  color: #6A52EE;
  padding: 16px 16px;
  margin-bottom: -60px;
  position: relative;
  z-index: 1;
}
.ProfitChart .VictoryContainer svg {
  margin-bottom: -7px;
  border-radius: 0 0 16px 16px;
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/components/ProfitChart/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACEA;EACE,kBAAA;EACA,UAAA;AAAF;AAEE;EACE,gBAAA;EACA,gBAAA;EACA,cDNK;ECOL,kBAAA;EACA,oBAAA;EACA,kBAAA;EACA,UAAA;AAAJ;AAEE;EACE,mBAAA;EACA,4BAAA;AAAJ","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../variables.scss';\n\n.ProfitChart {\n  position: relative;\n  z-index: 1;\n  \n  .title {\n    font-weight: 600;\n    font-size: 1.1em;\n    color: $purple;\n    padding: $md-space $md-space;\n    margin-bottom: -60px;\n    position: relative;\n    z-index: 1;\n  }\n  .VictoryContainer svg {\n    margin-bottom: -7px;\n    border-radius: 0 0 $radius $radius;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
