import { Button, Form, Input, message } from 'antd';
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import './style.scss';
import { updateCompany } from '../../../api/supabaseApi';

function CompanyForm({ itemData, action, setDrawerOpen }) {

  const onFinish = async (values) => {

    try {
      await updateCompany(itemData.ceoId, values);

      message.success('Company updated successfully!');

      const updatedItemData = {
        ...itemData,
        company: {
          ...itemData.company,
          ...values  // Sobrescrever/aplicar apenas os valores recebidos
        }
      };

      action(updatedItemData);
      setDrawerOpen(false);
    } catch (error) {
      console.error('Error:', error.message);
      message.error(`Falha na atualização: ${error.message}`);
    }

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="Companies">
      <div className="manage-companies-form">
        <Form
          layout="vertical"
          name="manage-companies-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{
            id: itemData?.company?.id || undefined,
            name: itemData?.company?.name || undefined,
            address1: itemData?.company?.address1 || undefined,
            address2: itemData?.company?.address2 || undefined,
            city: itemData?.company?.city || undefined,
            state: itemData?.company?.state || undefined,
            zipcode: itemData?.company?.zipcode || undefined,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Company name"
            name="name"
            rules={[{ required: true, message: 'Please input the company name!' }]}
          >
            <Input placeholder="Insert the name" />
          </Form.Item>

          <Form.Item
            label="Address 1"
            name="address1"
            rules={[{ required: true, message: 'Please input the address 1!' }]}
          >
            <Input placeholder="Insert the address 1" />
          </Form.Item>

          <Form.Item
            label="Address 2"
            name="address2"
            rules={[{ required: false, message: 'Please input the address 2!' }]}
          >
            <Input placeholder="Insert the address 1" />
          </Form.Item>

          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please input the city!' }]}
          >
            <Input placeholder="Insert the city" />
          </Form.Item>

          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: 'Please input the state!' }]}
          >
            <Input placeholder="Insert the state" />
          </Form.Item>

          <Form.Item
            label="Zipcode"
            name="zipcode"
            rules={[{ required: true, message: 'Please input the zipcode!' }]}
          >
            <Input placeholder="Insert the zipcode" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {itemData ? 'Update Company' : 'Create company'}
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CompanyForm;
