import { Link } from "react-router-dom";
import { 
  PieChartOutlined,
  AppstoreOutlined,
  FlagOutlined,
  SettingOutlined
} from '@ant-design/icons';
import './style.scss';
import { message } from 'antd';

const Menu = ({ location }) => {

  const showComingSoonMessage = (event) => {
    event.preventDefault();  // Impede a navegação
    message.info('Available soon.');
  };

  const menuItems = [
    {
      text: 'Dashboard',
      link: '/dashboard',
      icon: <PieChartOutlined/>,
      className: 'dashboard',
    },
    {
      text: 'Machines',
      link: '/machines',
      icon: <AppstoreOutlined/>,
      className: 'machines',
    },
    {
      text: 'Tasks',
      action: showComingSoonMessage,
      icon: <FlagOutlined/>,
      className: 'tasks',
    },
    {
      text: 'Manage',
      link: '/manage',
      icon: <SettingOutlined/>,
      className: 'manage',
    },
  ]
  return (
    <nav className="app-menu">
      <ul>
        {
          menuItems.map((menuItem, key) => (
            <li
              className={
                `${location.pathname.includes(menuItem.link) ? 
                  'menu-active ' : 
                  ''
                }${menuItem.className}`
              }
              key={key}
              >
            {menuItem.link ?
              <Link to={menuItem.link} className="menu-item-link">
                {menuItem.icon}
                <span className="menu-item-text">{menuItem.text}</span>
              </Link>
            :
              <a href="/" className="menu-item-link" onClick={menuItem.action}>
                {menuItem.icon}
                <span className="menu-item-text">{menuItem.text}</span>
              </a>
            }
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

export default Menu;