// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/login-arc.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.auth-page {
  background: #FFF url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat top center;
  background-size: cover;
  max-width: 640px;
  width: 100vw;
  min-height: 100dvh;
  position: relative;
  padding: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-page .auth-logo {
  position: absolute;
  top: 16px;
  left: 48px;
}
.auth-page .auth-logo img {
  width: 100px;
}
.auth-page h1 {
  line-height: 1em;
}
@media screen and (min-width: 640px) {
  .auth-page::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1B1B51;
    z-index: -1;
    pointer-events: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/components/Layouts/Authentication/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACEA;EACE,6EAAA;EACA,sBAAA;EACA,gBDSa;ECRb,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAEE;EACE,kBAAA;EACA,SDLO;ECMP,UDRO;ACQX;AAEI;EACE,YAAA;AAAN;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE;IACE,WAAA;IACA,eAAA;IACA,MAAA;IACA,OAAA;IACA,WAAA;IACA,YAAA;IACA,yBDlCE;ICmCF,WAAA;IACA,oBAAA;EAHJ;AACF","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../../variables.scss';\n\n.auth-page {\n  background: #FFF url('../../../assets/images/login-arc.svg') no-repeat top center;\n  background-size: cover;\n  max-width: $mobile-width;\n  width: 100vw;\n  min-height: 100dvh;\n  position: relative;\n  padding: $xl-space;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .auth-logo {\n    position: absolute;\n    top: $md-space;\n    left: $xl-space;\n\n    img{\n      width: 100px;\n    }\n  }\n\n  h1 {\n    line-height: 1em;\n  }\n\n  @media screen and (min-width: $mobile-width) {\n    &::after {\n      content: '';\n      position: fixed;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      background-color: $black;\n      z-index: -1;\n      pointer-events: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
