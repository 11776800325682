import ReactDOM from "react-dom/client";
import { ConfigProvider } from 'antd';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { StoreProvider } from "store/main";

import Layout from "./components/Layouts/Default";
import AuthLayout from "./components/Layouts/Authentication";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Machines from "./pages/Machines";
import MachinesItem from "pages/Machines/Item";
import Locations from "./pages/Locations";
import LocationsItem from "./pages/Locations/Item";
import Tasks from "./pages/Tasks";
import Technicians from "./pages/Technicians";
import Managers from "./pages/Managers";
import Manage from "./pages/Manage";
import Error from "./pages/Error";
import PrivateRoute from "./components/PrivateRoute";

import './index.scss';

export default function App() {
  return (
    <StoreProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#2DD673',
            colorTextBase: '#1B1B51',
            fontFamily: 'Poppins',
            fontSize: 16,
            borderRadius: 16,
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="login/" element={<AuthLayout />}>
              <Route index element={<Login />} />
            </Route>
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="/login" replace={true} />} />

              <Route path="dashboard" element={
                <PrivateRoute
                  element={Dashboard}
                  allowedRoles={['ceo']}
                />
              } />

              <Route path="machines/:id" element={<MachinesItem />} />
              <Route path="machines" element={<Machines />} />
              <Route path="tasks" element={<Tasks />} />
              <Route path="tasks/:id" element={<Tasks />} />
              <Route path="manage/">
                <Route index element={<Manage />} />
                <Route path="locations" element={<Locations />} />
                <Route path="locations/:id" element={<LocationsItem />} />
                <Route path="technicians" element={<Technicians />} />
                <Route path="technicians/:id" element={<Technicians />} />
                <Route path="managers" element={<Managers />} />
                <Route path="managers/:id" element={<Managers />} />
              </Route>
              <Route path="manage/:id" element={<Manage />} />
            </Route>
            <Route path="logout" element={<Navigate to="/login" replace={true} />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </StoreProvider >
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);