import { useOutletContext } from "react-router-dom";
import { Button, Select, message } from 'antd';
import { useStoreInContext } from 'store/main';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import { formatCurrency, formattedLocationOptions } from "utils/formatters";
import { getLocationNameById } from "utils";
import MachineForm from './MachineForm';
import './style.scss';
import { EnvironmentOutlined, TagOutlined } from "@ant-design/icons";
import { updateMachineDB, createMachineDB, deleteMachineDB } from '../../api/supabaseApi';
import React, { useState } from 'react'; // Certifique-se de importar useState

function Machines() {
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const { machines, editMachine, deleteMachine, addMachine, locations, loggedUser } = useStoreInContext(state => state);
  const refreshAction = () => true;
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const formattedCardListData = (cardData, actions) => {
    if (!cardData) return [];

    // Ordenar cardData pelo campo node, convertendo-o para número
    const sortedCardData = cardData.sort((a, b) => parseInt(a.node) - parseInt(b.node));

    return sortedCardData.map((item) => ({
      link: `/machines/${item.id}`,
      title: item.name,
      list: [
        {
          icon: <EnvironmentOutlined />,
          text: getLocationNameById(locations, item.location_id)
        },
        {
          icon: <TagOutlined />,
          text: `Node: ${item.node}`
        },
      ],
      actions: [
        {
          action: () => actions.handleEditItem(item),
          name: 'Edit Machine'
        },
        {
          action: () => actions.handleDeleteItem(item),
          name: 'Delete Machine'
        },
      ]
    }));
  }

  const deleteMachineOnDatabase = (machine) => {
    deleteMachineDB(machine.id)
      .then(() => {
        deleteMachine(machine.id);  // Supondo que esta função atualiza o estado local
        message.success('Machine deleted successfully!');
      })
      .catch((error) => {
        console.error("Failed to delete machine:", error.message);
        message.error("Failed to delete machine: " + error.message);  // Mostra uma mensagem de erro ao usuário
      });
  };

  const updateMachineOnDatabase = (machine) => {
    updateMachineDB(machine.id, machine)
      .then(() => {
        editMachine(machine);  // Supondo que esta função atualiza o estado local
        message.success('Machine updated successfully!');
      })
      .catch((error) => {
        console.error("Failed to update machine:", error.message);
        message.error("Failed to update machine: " + error.message);  // Mostra uma mensagem de erro ao usuário
      });
  };

  const createMachineOnDatabase = (newMachine) => {
    const { id, ...newMachineSemID } = newMachine;
    const machineWithUserId = {
      ...newMachineSemID,
      user_id: loggedUser.id,
    };

    createMachineDB(machineWithUserId)
      .then((newData) => {
        if (newData) {
          addMachine(newData);
        }
        message.success('Location created successfully!');
      })
      .catch((error) => {
        console.error("Failed to create location:", error.message);
        message.error("Failed to create location: " + error.message);
      });
  };

  const handleNewItem = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'New Machine',
      height: 640,
      content: <MachineForm
        action={createMachineOnDatabase}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleEditItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Edit Machine',
      height: 640,
      content: <MachineForm
        itemData={item}
        action={updateMachineOnDatabase}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleDeleteItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Delete Machine',
      height: 320,
      content: <Button
        onClick={() => {
          deleteMachineOnDatabase(item)
          return setDrawerOpen(false)
        }}
        danger>
        Confirm deletion
      </Button>
    })
    return setDrawerOpen(true);
  }

  const locationsOptions = formattedLocationOptions(locations);
  const filteredMachines = selectedLocationId
    ? machines.filter(machine => machine.location_id === selectedLocationId)
    : machines;

  const dataList = formattedCardListData(filteredMachines, { handleEditItem, handleDeleteItem });

  return (
    <div className="Machines">
      <Header
        title={'Machines'}
        refreshAction={refreshAction}
      >
        <div className="form-item">
          <div className="form-item-label">Filter by location</div>
          <Select
            name="locationFilter"
            placeholder="Select the location"
            options={locationsOptions}
            onChange={value => setSelectedLocationId(value)} // Atualiza o estado quando a seleção muda
          />
        </div>
      </Header>
      {machines ?
        <CardList
          data={dataList}
          createAction={handleNewItem}
        />
        :
        <>LOADING...</>
      }
      <FloatButton action={handleNewItem} />
    </div>
  );
}

export default Machines;
