// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.ManageMenuItem {
  background: #FFF;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  text-decoration: none;
  color: #6A52EE;
  align-items: flex-start;
  border: 1px solid #D7E2EF;
}
.ManageMenuItem.style-boxed {
  width: calc(50% - 8px);
}
.ManageMenuItem .menu-icon {
  font-size: 2em;
  margin-top: -10px;
}
.ManageMenuItem .menu-title {
  font-size: 1em;
  margin-bottom: 4px;
}
.ManageMenuItem .menu-subtitle {
  color: #D7E2EF;
  line-height: 1.1em;
  font-size: 0.7em;
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/pages/Manage/ManageMenuItem/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACEA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,aDKS;ECJT,mBDIS;ECHT,qBAAA;EACA,cDNO;ECOP,uBAAA;EACA,yBAAA;AAAF;AAEE;EACE,sBAAA;AAAJ;AAII;EACE,cAAA;EACA,iBAAA;AAFN;AAII;EACE,cAAA;EACA,kBAAA;AAFN;AAII;EACE,cDvBC;ECwBD,kBAAA;EACA,gBAAA;AAFN","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../../variables.scss';\n\n.ManageMenuItem {\n  background: #FFF;\n  display: flex;\n  flex-direction: column;\n  padding: $md-space;\n  border-radius: $radius;\n  text-decoration: none;\n  color: $purple;\n  align-items: flex-start;\n  border: 1px solid $grey;\n\n  &.style-boxed {\n    width: calc(50% - ($md-space / 2));\n  }\n\n  .menu {\n    &-icon {\n      font-size: 2em;\n      margin-top: -10px;\n    }\n    &-title {\n      font-size: 1em;\n      margin-bottom: 4px;\n    }\n    &-subtitle {\n      color: $grey;\n      line-height: 1.1em;\n      font-size: 0.7em;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
