import supabase from './supabaseClient';
import { loginUser, fetchProfile, fetchCeo, fetchCompany } from '../api/supabaseApi';
import { message } from 'antd';

export async function handleLogin(email, password, action, navigate) {
    let user, access_token, refresh_token;
    try {
        if (email && password) {
            // Login com credenciais
            const loginData = await loginUser(email, password);
            user = loginData.user;
            access_token = loginData.access_token;
            refresh_token = loginData.refresh_token;
        } else {
            // Tentativa de usar a sessão atual
            const { data } = await supabase.auth.refreshSession()
            let session = data.session;
            user = data.user;
            access_token = session.access_token;
            refresh_token = session.refresh_token;
        }

        // Continuar com a busca de dados complementares
        const profile = await fetchProfile(user.id);
        const ceo = await fetchCeo(profile.id);
        const company = await fetchCompany(ceo.id);

        const userData = {
            access_token,
            refresh_token,
            id: user.id,
            ceoId: ceo.id,
            aud: user.aud,
            role: profile.role,
            email: user.email,
            name: profile.name,
            profile_role: profile.role,
            company: company
        };
        action(userData);
        //message.success('Login successful!');
        navigate && navigate("/dashboard");
    } catch (error) {
        console.error('Login error:', error.message);
        message.error('Login failed! ' + error.message);
    }
}
