import { Button, Form, Input } from 'antd';
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import './style.scss';

function TechnicianForm({ itemData, action, setDrawerOpen }) {
  const onFinish = (values) => {
    action({
      ...values,
      companyId: '1',
      locationId: null,
      roleId: '3'
    });
    return setDrawerOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="NewTechnicians">
      <div className="new-technicians-form">
        <Form
          layout="vertical"
          name="new-technicians-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{
            id: itemData?.id || undefined,
            name: itemData?.name || undefined,
            email: itemData?.email || undefined,
            username: itemData?.username || undefined,
            password: itemData?.password || undefined,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Technician ID"
            name="id"
            rules={[{ required: true, message: 'Please input the technician ID!' }]}
          >
            <Input placeholder="Insert the name" />
          </Form.Item>
          <Form.Item
            label="Technician name"
            name="name"
            rules={[{ required: true, message: 'Please input the technician name!' }]}
          >
            <Input placeholder="Insert the name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input the Technician Email!' }]}
          >
            <Input placeholder="Insert the technician ID" />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input the username!' }]}
          >
            <Input placeholder="Insert the zipcode" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please insert the password!' }]}
          >
            <Input.Password type="password" placeholder="Insert the password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {itemData ? 'Update Technician' : 'Create technician'}
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default TechnicianForm;
