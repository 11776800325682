import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStoreInContext } from 'store/main'; // Importar o hook do Zustand


const PrivateRoute = ({ element: Component, allowedRoles }) => {
    const location = useLocation();
    const { loggedUser } = useStoreInContext();
    // Verificar se o usuário está logado
    if (!loggedUser) {
        // Redirecionar para a tela de login, mantendo a rota de destino para redirecionamento pós-login
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Verificar se o usuário tem o perfil necessário para acessar a rota
    if (allowedRoles && !allowedRoles.includes(loggedUser.role)) {
        // Redirecionar para uma tela de erro ou dashboard se não tiver permissão
        return <Navigate to="/error" replace />;
    }

    return <Component />;
};


export default PrivateRoute;
