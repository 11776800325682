import { AppstoreOutlined, EnvironmentOutlined, NotificationOutlined, TeamOutlined } from '@ant-design/icons';
import ManageMenuItem from './ManageMenuItem';
import Header from "components/Header";
import { useOutletContext, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getCompanyDataById } from 'utils';
import { useStoreInContext } from 'store/main';
import AccountForm from './AccountForm';
import './style.scss';
import CompanyForm from './CompanyForm';
import { Modal } from 'antd';
import { logoutUser } from '../../api/supabaseApi';
import { message } from 'antd';


function Manage() {
  let { id } = useParams();
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const { editLoggedUser, loggedUser, editCompany, companies } = useStoreInContext(state => state);
  const company = getCompanyDataById(companies, '1');

  useEffect(() => {
    if (id && id === 'account') {
      handleEditAccount(loggedUser);
    }
    if (id && id === 'company') {
      handleEditCompany(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showComingSoonMessage = () => {
    message.info('Available soon.');
  };

  const handleEditAccount = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Your Account',
      height: 520,
      content: <AccountForm
        itemData={item}
        action={editLoggedUser}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleEditCompany = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Your Company',
      height: 640,
      content: <CompanyForm
        itemData={item}
        action={editCompany}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleLogout = () => {

    Modal.confirm({
      title: 'Confirm Logout',
      content: 'Are you sure you want to logout?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        logoutUser()
          .then(() => {
            console.log('Logout successful');
            window.location.href = '/login';
          })
          .catch((error) => {
            console.error('Failed to logout:', error.message);
          });
      }
    });
  };


  return (
    <div className="Manage">
      <Header title="Manage">
        <div className="featured-menu-items">
          <ManageMenuItem
            //link="/manage/technicians"
            action={showComingSoonMessage}
            title="Technicians"
            subtitle="List, Edit and create new technical users"
            icon={<TeamOutlined />}
            type="boxed"
          />
          <ManageMenuItem
            action={showComingSoonMessage}
            //link="/manage/managers"
            title="Managers"
            subtitle="List, Edit and create new manager users"
            icon={<NotificationOutlined />}
            type="boxed"
          />
          <ManageMenuItem
            link="/manage/locations"
            title="Locations"
            subtitle="List, Edit and create new locations"
            icon={<EnvironmentOutlined />}
            type="boxed"
          />
          <ManageMenuItem
            link="/machines"
            title="Machines"
            subtitle="List, Edit and create new machines"
            icon={<AppstoreOutlined />}
            type="boxed"
          />
        </div>
        <div className="menu-list">
          <ManageMenuItem
            action={() => handleEditAccount(loggedUser)}
            title="Your account"
            subtitle="View and edit your data, and password."
          />
          <ManageMenuItem
            action={() => handleEditCompany(loggedUser)}
            title="Your company"
            subtitle="View and edit your company information."
          />
        </div>
      </Header>
      <div className="bottom-menu">
        <ManageMenuItem
          action={handleLogout}
          title="Logout"
          subtitle="Logout of your account."
        />
      </div>
    </div>
  );
}

export default Manage;
