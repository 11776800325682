import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';
import supabase from '../api/supabaseClient';
import { handleLogin } from '../api/authService';
import { fetchInitialData, fetchDataLocation, fetchDataMachine } from '../api/supabaseApi';

const StoreContext = createContext(null);

const useZustandStore = createStore((set) => ({
  companies: [],
  locations: [],
  machines: [],
  technicians: [],
  tasks: [],
  loggedUser: null,

  setCompanies: (companies) => set({ companies }),
  addCompany: (company) => set((state) => ({ companies: [...state.companies, company] })),
  editCompany: (company) => set((state) => ({
    companies: state.companies.map(c => c.id === company.id ? { ...c, ...company } : c)
  })),
  deleteCompany: (companyId) => set((state) => ({
    companies: state.companies.filter(c => c.id !== companyId)
  })),

  setLocations: (locations) => set({ locations }),
  addLocation: (location) => set((state) => ({ locations: [...state.locations, location] })),
  editLocation: (location) => set((state) => ({
    locations: state.locations.map(l => l.id === location.id ? { ...l, ...location } : l)
  })),
  deleteLocation: (locationId) => set((state) => ({
    locations: state.locations.filter(l => l.id !== locationId)
  })),

  setMachines: (machines) => set({ machines }),
  addMachine: (machine) => set((state) => ({ machines: [...state.machines, machine] })),
  editMachine: (machine) => set((state) => ({
    machines: state.machines.map(l => l.id === machine.id ? { ...l, ...machine } : l)
  })),
  deleteMachine: (machineId) => set((state) => ({
    machines: state.machines.filter(m => m.id !== machineId)
  })),

  setTechnicians: (technicians) => set({ technicians }),
  addTechnician: (technician) => set((state) => ({ technicians: [...state.technicians, technician] })),
  editTechnician: (technician) => set((state) => ({
    technicians: state.technicians.map(l => l.id === technician.id ? { ...l, ...technician } : l)
  })),
  deleteTechnician: (technicianId) => set((state) => ({
    technicians: state.technicians.filter(m => m.id !== technicianId)
  })),

  setManagers: (managers) => set({ managers }),
  addManager: (manager) => set((state) => ({ managers: [...state.managers, manager] })),
  editManager: (manager) => set((state) => ({
    managers: state.managers.map(l => l.id === manager.id ? { ...l, ...manager } : l)
  })),
  deleteManager: (managerId) => set((state) => ({
    managers: state.managers.filter(m => m.id !== managerId)
  })),

  setTasks: (tasks) => set({ tasks }),
  addTask: (task) => set((state) => ({ tasks: [...state.tasks, task] })),
  editTask: (task) => set((state) => ({
    tasks: state.tasks.map(l => l.id === task.id ? { ...l, ...task } : l)
  })),
  deleteTask: (taskId) => set((state) => ({
    tasks: state.tasks.filter(t => t.id !== taskId)
  })),

  setLoggedUser: (user) => set({ loggedUser: user }),
  editLoggedUser: (updates) => set((state) => ({
    loggedUser: { ...state.loggedUser, ...updates }
  })),
}));

export const StoreProvider = ({ children }) => {
  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = useZustandStore;


    supabase.auth.onAuthStateChange((_event, session) => {
      if (_event === 'SIGNED_IN') {
        const user = session?.user;
        const state = storeRef.current.getState();

        if (user) {

          handleLogin(null, null, state.setLoggedUser, null);

          fetchDataLocation().then(data => {
            state.setLocations(data);
          });

          fetchDataMachine().then(data => {
            state.setMachines(data);
          });

          fetchInitialData().then(data => {

            state.setCompanies(data.companies);
            state.setMachines(data.machines);
            state.setTechnicians(data.technicians);
            state.setManagers(data.managers);
            state.setTasks(data.tasks);
            // Set other data similarly
          });
        } else {
          // Clear all data on logout
          state.setCompanies([]);
          //state.setLocations([]);
          //state.setMachines([]);
          state.setTechnicians([]);
          state.setManagers([]);
          state.setTasks([]);
          // Clear other data similarly
        }
      }
    });
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStoreInContext = (selector) => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('Missing StoreProvider');
  }
  return useStore(store, selector);
};
