// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.float-button {
  position: fixed;
  bottom: 120px;
  right: 16px;
  z-index: 999;
  font-size: 2em;
  color: #FFF;
  background: #2DD673;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media screen and (min-width: 640px) {
  .float-button {
    right: calc(50% - 320px + 16px);
  }
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/components/FloatButton/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACEA;EACE,eAAA;EACA,aAAA;EACA,WDMS;ECLT,YAAA;EACA,cAAA;EACA,WAAA;EACA,mBDHM;ECIN,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAAF;AAEE;EAhBF;IAiBI,+BAAA;EACF;AACF","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../variables.scss';\n\n.float-button {\n  position: fixed;\n  bottom: 120px;\n  right: $md-space;\n  z-index: 999;\n  font-size: 2em;\n  color: #FFF;\n  background: $green;\n  width: 64px;\n  height: 64px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n\n  @media screen and (min-width: $mobile-width) {\n    right: calc(50% - ($mobile-width / 2) + $md-space);  \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
