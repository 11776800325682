import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Button, Select } from 'antd';
import { useStoreInContext } from 'store/main';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import TaskForm from './TaskForm';
import { AppstoreOutlined, EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import { getLocationNameById, getMachineDataById, getTaskDataById, getUserDataById } from "utils";
import { formattedUserByRoleOptions } from "utils/formatters";
import './style.scss';


export const statusList = {
  toDo: {
    label: 'To Do',
    color: 'red'
  },
  doing: {
    label: 'In Progress',
    color: 'yellow'
  },
  done: {
    label: 'Done',
    color: 'green'
  },
}

export const formattedStatusOptions = (statusList) => Object.entries(statusList).map((status) => ({
  value: status[0],
  label: status[1].label,
}))

const getClassByStatus = (status) => statusList[status]?.color || '';

function Tasks() {
  let { id } = useParams();
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const {
    tasks,
    editTask,
    deleteTask,
    addTask,
    technicians,
    locations,
    machines,
  } = useStoreInContext(state => state);
  const techniciansOptions = formattedUserByRoleOptions(technicians, '3');
  const statusOptions = formattedStatusOptions(statusList);

  useEffect(() => {
    if (id) {
      const task = getTaskDataById(tasks, id);
      handleEditItem(task);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAction = () => true;

  const formattedCardListData = (cardData, actions) => {
    if (!cardData) return [];

    return cardData.map((item) => ({
      icon: <div className={`task-status-dot ${getClassByStatus(item.status)}`}></div>,
      title: item.title,
      list: [
        {
          icon: <UserOutlined />,
          text: getUserDataById(technicians, item.userId)?.name
        },
        {
          icon: <AppstoreOutlined />,
          text: getMachineDataById(machines, item.machineId)?.name
        },
        {
          icon: <EnvironmentOutlined />,
          text: getLocationNameById(locations, item.locationId)
        },
        {
          icon: <div className={`anticon task-status-dot ${getClassByStatus(item.status)}`}></div>,
          text: statusList[item.status]?.label
        },
      ],
      actions: [
        {
          action: () => actions.handleEditItem(item),
          name: 'Edit Task'
        },
        {
          action: () => actions.handleDeleteItem(item),
          name: 'Delete Task'
        },
      ]
    }))
  }

  const handleNewItem = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'New Task',
      height: 640,
      content: <TaskForm
        action={addTask}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleEditItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Edit Task',
      height: 640,
      content: <TaskForm
        itemData={item}
        action={editTask}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleDeleteItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Delete Task',
      height: 320,
      content: <Button
        onClick={() => {
          deleteTask(item)
          return setDrawerOpen(false)
        }}
        danger>
        Confirm deletion
      </Button>
    })
    return setDrawerOpen(true);
  }

  const dataList = formattedCardListData(tasks, { handleEditItem, handleDeleteItem });

  return (
    <div className="Tasks">
      <Header
        title={'Tasks'}
        refreshAction={refreshAction}
      >
        <div className="form-item">
          <div className="form-item-label">Filter by Technician</div>
          <Select
            name="technicianFilter"
            placeholder="Select the technician"
            options={techniciansOptions}
          />
        </div>
        <div className="form-item">
          <div className="form-item-label">Filter by Status</div>
          <Select
            name="statusFilter"
            placeholder="All Statuses"
            options={statusOptions}
          />
        </div>
      </Header>
      {tasks ?
        <CardList
          data={dataList}
          createAction={handleNewItem}
        />
        :
        <>LOADING...</>
      }
      <FloatButton action={handleNewItem} />
    </div>
  )
}

export default Tasks;
