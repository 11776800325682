// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.dateRangeRadio .ant-radio-group {
  width: 100%;
  display: flex;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
}
.dateRangeRadio .ant-radio-group .dateRangeRadio-item {
  border-radius: 16px;
  border: 0;
  background-color: transparent;
  height: auto;
  color: #FFF;
  flex: 1 1;
  text-align: center;
  padding: 8px 16px;
}
.dateRangeRadio .ant-radio-group .dateRangeRadio-item::before {
  display: none;
}
.dateRangeRadio .ant-radio-group .dateRangeRadio-item.ant-radio-button-wrapper-checked {
  background-color: #FFF;
  color: #6A52EE;
}
.dateRangeRadio .ant-radio-group .dateRangeRadio-item span {
  font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/components/DateRangeRadio/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACGE;EACE,WAAA;EACA,aAAA;EACA,mBDKO;ECJP,0CAAA;EACA,0CAAA;AADJ;AAGI;EACE,mBAAA;EACA,SAAA;EACA,6BAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;AADN;AAGM;EACE,aAAA;AADR;AAIM;EACE,sBAAA;EACA,cDvBC;ACqBT;AAKM;EACE,gBAAA;AAHR","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../variables.scss';\n\n.dateRangeRadio {\n  .ant-radio-group {\n    width: 100%;\n    display: flex;\n    border-radius: $radius;\n    border: 1px solid rgba($color: #FFF, $alpha: .3);\n    background-color: rgba($color: #FFF, $alpha: .2);\n\n    & .dateRangeRadio-item {\n      border-radius: $radius;\n      border: 0;\n      background-color: transparent;\n      height: auto;\n      color: #FFF;\n      flex: 1;\n      text-align: center;\n      padding: $sm-space $md-space;\n\n      &::before {\n        display: none;\n      }\n\n      &.ant-radio-button-wrapper-checked {\n        background-color: #FFF;\n        color: $purple;\n      }\n\n      span {\n        font-size: .8em;\n      }\n    } \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
