import { useOutletContext } from "react-router-dom";
import { Button, message } from 'antd';
import { useStoreInContext } from 'store/main';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import { formatCurrency } from "utils/formatters";
import LocationForm from './LocationForm';
import './style.scss';
import { EnvironmentOutlined, TagOutlined } from "@ant-design/icons";
import { updateLocationDB, createLocationDB, deleteLocationDB } from '../../api/supabaseApi';
import { generateShortUUID } from '../../utils';

// [TO DO] CREATE THAT FUNCTION PROPERLY
const getLocationTotalProfit = () => Math.floor(Math.random() * (880 - 120 + 1) + 120)

function Locations() {
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const { locations, editLocation, deleteLocation, addLocation, loggedUser } = useStoreInContext(state => state);
  const refreshAction = () => true;

  const formattedCardListData = (cardData, actions) => {
    if (!cardData) return [];

    return cardData.map((item) => ({
      link: `/manage/locations/${item.id}`,
      title: item.name,
      list: [
        {
          icon: <EnvironmentOutlined />,
          text: `${item.city}/${item.state}`
        },
        {
          icon: <TagOutlined />,
          text: `ID: ${item.location_code}`
        },
      ],
      actions: [
        {
          action: () => actions.handleEditItem(item),
          name: 'Edit Location'
        },
        {
          action: () => actions.handleDeleteItem(item),
          name: 'Delete Location'
        },
      ]
    }))
  }

  const deleteLocationOnDatabase = (location) => {
    deleteLocationDB(location.id)
      .then(() => {
        deleteLocation(location.id);  // Supondo que esta função atualiza o estado local
        // Aqui você pode adicionar uma mensagem de sucesso
        message.success('Location deleted successfully!');
      })
      .catch((error) => {
        // Aqui você pode lidar com o erro, por exemplo, mostrando uma mensagem para o usuário
        console.error("Failed to delete location:", error.message);
        message.error("Failed to delete location: " + error.message);  // Mostra uma mensagem de erro ao usuário
      });
  };

  const updateLocationOnDatabase = (location) => {
    updateLocationDB(location.id, location)
      .then(() => {
        editLocation(location);  // Supondo que esta função atualiza o estado local
        // Aqui você pode adicionar uma mensagem de sucesso
        message.success('Location updated successfully!');
      })
      .catch((error) => {
        // Aqui você pode lidar com o erro, por exemplo, mostrando uma mensagem para o usuário
        console.error("Failed to update location:", error.message);
        message.error("Failed to update location: " + error.message);  // Mostra uma mensagem de erro ao usuário
      });
  };

  const createLocationOnDatabase = (newLocation) => {
    const { id, ...newLocationSemID } = newLocation;
    const locationWithCompanyId = {
      ...newLocationSemID,
      company_id: loggedUser.company.id,
      location_code: generateShortUUID(),
      user_id: loggedUser.id,
    };

    createLocationDB(locationWithCompanyId)
      .then((newData) => {
        if (newData) {
          addLocation(newData);
        }

        message.success('Location created successfully!');
      })
      .catch((error) => {
        console.error("Failed to create location:", error.message);
        message.error("Failed to create location: " + error.message);
      });
  };

  const handleNewItem = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'New Location',
      height: 640,
      content: <LocationForm
        action={createLocationOnDatabase}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleEditItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Edit Location',
      height: 640,
      content: <LocationForm
        itemData={item}
        action={updateLocationOnDatabase}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleDeleteItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Delete Location',
      height: 320,
      content: <Button
        onClick={() => {
          deleteLocationOnDatabase(item)
          return setDrawerOpen(false)
        }}
        danger>
        Confirm deletion
      </Button>
    })
    return setDrawerOpen(true);
  }

  const dataList = formattedCardListData(locations, { handleEditItem, handleDeleteItem });

  return (
    <div className="Locations">
      <Header
        title={'Locations'}
        refreshAction={refreshAction}
      />
      {locations ?
        <CardList
          data={dataList}
          createAction={handleNewItem}
        />
        :
        <>LOADING...</>
      }
      <FloatButton action={handleNewItem} />
    </div>
  )
}

export default Locations;
