// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.dateRangeNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 0 0 0;
}
.dateRangeNav .dateRangeNav-button {
  background: transparent;
  padding: 0 !important;
  border: 0;
  color: #FFF;
  height: auto;
  width: 92px;
  min-width: 0;
  margin: 0;
  justify-content: unset;
  font-size: 0.9em;
}
.dateRangeNav .dateRangeNav-button:hover {
  background: transparent !important;
}
.dateRangeNav .dateRangeNav-button:hover .anticon {
  transform: none;
}
.dateRangeNav .dateRangeNav-button.next {
  justify-content: flex-end;
}
.dateRangeNav .dateRangeNav-button:disabled {
  opacity: 0.5;
  background: none;
}
.dateRangeNav .dateRangeNav-current {
  font-size: 1.1em;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/components/DateRangeNav/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;AAAF;AAEE;EACE,uBAAA;EACA,qBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,kCAAA;AAAN;AAEM;EACE,eAAA;AAAR;AAII;EACE,yBAAA;AAFN;AAII;EACE,YAAA;EACA,gBAAA;AAFN;AAME;EACE,gBAAA;EACA,mBAAA;AAJJ","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../variables.scss';\n\n.dateRangeNav {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  padding: $sm-space 0 0 0;\n\n  .dateRangeNav-button {\n    background: transparent;\n    padding: 0 !important;\n    border: 0;\n    color: #FFF;\n    height: auto;\n    width: 92px;\n    min-width: 0;\n    margin: 0;\n    justify-content: unset;\n    font-size: .9em;\n\n    &:hover {\n      background: transparent !important;\n\n      .anticon {\n        transform: none;\n      }\n    }\n\n    &.next {\n      justify-content: flex-end;\n    }\n    &:disabled {\n      opacity: .5;\n      background: none;\n    }\n  }\n\n  .dateRangeNav-current {\n    font-size: 1.1em;\n    white-space: nowrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
