import { useOutletContext, useParams } from "react-router-dom";
import { Button, Select } from 'antd';
import { useStoreInContext } from 'store/main';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import ManagerForm from './ManagerForm';
import { EnvironmentOutlined, MailOutlined } from "@ant-design/icons";
import { getLocationNameById, getUserDataById } from "utils";
import './style.scss';
import { useEffect } from "react";
import { formattedLocationOptions } from "utils/formatters";

function Managers() {
  let { id } = useParams();
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const { managers, editManager, deleteManager, addManager, locations } = useStoreInContext(state => state);

  const locationsOptions = formattedLocationOptions(locations);

  useEffect(() => {
    if (id) {
      const manager = getUserDataById(managers, id);
      if (manager.roleId === '2') handleEditItem(manager);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAction = () => true;

  const formattedCardListData = (cardData, actions) => {
    if (!cardData) return [];

    return cardData.map((item) => ({
      title: item.name,
      list: [
        {
          icon: <EnvironmentOutlined />,
          text: getLocationNameById(locations, item.locationId)
        },
        {
          icon: <MailOutlined />,
          text: item.email
        },
      ],
      actions: [
        {
          action: () => actions.handleEditItem(item),
          name: 'Edit Manager'
        },
        {
          action: () => actions.handleDeleteItem(item),
          name: 'Delete Manager'
        },
      ]
    }))
  }

  const handleNewItem = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'New Manager',
      height: 640,
      content: <ManagerForm
        action={addManager}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleEditItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Edit Manager',
      height: 640,
      content: <ManagerForm
        itemData={item}
        action={editManager}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleDeleteItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Delete Manager',
      height: 320,
      content: <Button
        onClick={() => {
          deleteManager(item)
          return setDrawerOpen(false)
        }}
        danger>
        Confirm deletion
      </Button>
    })
    return setDrawerOpen(true);
  }

  const dataList = formattedCardListData(managers, { handleEditItem, handleDeleteItem });

  return (
    <div className="Managers">
      <Header
        title={'Managers'}
        refreshAction={refreshAction}
      >
        <div className="form-item">
          <div className="form-item-label">Filter by location</div>
          <Select
            name="locationFilter"
            placeholder="Select the location"
            options={locationsOptions}
          />
        </div>
      </Header>
      {managers ?
        <CardList
          data={dataList}
          createAction={handleNewItem}
        />
        :
        <>LOADING...</>
      }
      <FloatButton action={handleNewItem} />
    </div>
  )
}

export default Managers;
