import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Button } from 'antd';
import { useStoreInContext } from 'store/main';
import Header from "components/Header";
import FloatButton from "components/FloatButton";
import CardList from "components/CardList";
import TechnicianForm from './TechnicianForm';
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { getTechniciansData, getUserDataById } from "utils";
import './style.scss';

function Technicians() {
  let { id } = useParams();
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const { technicians, editTechnician, deleteTechinican, addTechnician } = useStoreInContext(state => state);

  useEffect(() => {
    if (id) {
      const technician = getUserDataById(technicians, id);
      if (technician.roleId === '3') handleEditItem(technician);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAction = () => true;

  const formattedCardListData = (cardData, actions) => {
    if (!cardData) return [];

    return cardData.map((item) => ({
      title: item.name,
      list: [
        {
          icon: <UserOutlined />,
          text: item.username
        },
        {
          icon: <MailOutlined />,
          text: item.email
        },
      ],
      actions: [
        {
          action: () => actions.handleEditItem(item),
          name: 'Edit Technician'
        },
        {
          action: () => actions.handleDeleteItem(item),
          name: 'Delete Technician'
        },
      ]
    }))
  }

  const handleNewItem = () => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'New Technician',
      height: 640,
      content: <TechnicianForm
        action={addTechnician}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleEditItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Edit Technician',
      height: 640,
      content: <TechnicianForm
        itemData={item}
        action={editTechnician}
        setDrawerOpen={setDrawerOpen} />
    })
    return setDrawerOpen(true);
  }

  const handleDeleteItem = (item) => {
    setDrawerProps({
      closable: false,
      push: true,
      title: 'Delete Technician',
      height: 320,
      content: <Button
        onClick={() => {
          deleteTechinican(item)
          return setDrawerOpen(false)
        }}
        danger>
        Confirm deletion
      </Button>
    })
    return setDrawerOpen(true);
  }

  const techniciansData = getTechniciansData(technicians);

  const dataList = formattedCardListData(techniciansData, { handleEditItem, handleDeleteItem });

  return (
    <div className="Technicians">
      <Header
        title={'Technicians'}
        refreshAction={refreshAction}
      />
      {techniciansData ?
        <CardList
          data={dataList}
          createAction={handleNewItem}
        />
        :
        <>LOADING...</>
      }
      <FloatButton action={handleNewItem} />
    </div>
  )
}

export default Technicians;
