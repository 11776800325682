// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.Manage .featured-menu-items {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.Manage .menu-list {
  margin-bottom: -200px;
}
.Manage .menu-list .ManageMenuItem {
  margin-bottom: -1px;
}
.Manage .menu-list .ManageMenuItem:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.Manage .menu-list .ManageMenuItem:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.Manage .bottom-menu {
  margin-top: 232px;
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/pages/Manage/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACGE;EACE,aAAA;EACA,SDMO;ECLP,eAAA;EACA,mBDIO;ACLX;AAIE;EACE,qBAAA;AAFJ;AAII;EACE,mBAAA;AAFN;AAIM;EACE,4BAAA;EACA,6BAAA;AAFR;AAIM;EACE,yBAAA;EACA,0BAAA;AAFR;AAOE;EACE,iBAAA;AALJ","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../variables.scss';\n\n.Manage {\n  .featured-menu-items {\n    display: flex;\n    gap: $md-space;\n    flex-wrap: wrap;\n    margin-bottom: $md-space;\n  }\n\n  .menu-list {\n    margin-bottom: -200px;\n\n    .ManageMenuItem{\n      margin-bottom: -1px;\n\n      &:first-child {\n        border-bottom-left-radius: 0;\n        border-bottom-right-radius: 0;\n      }\n      &:last-child {\n        border-top-left-radius: 0;\n        border-top-right-radius: 0;\n      }\n    }\n  }\n\n  .bottom-menu {\n    margin-top: 232px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
