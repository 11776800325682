// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.app-container {
  background-color: #FFF;
  max-width: 640px;
  width: 100vw;
  min-height: 100dvh;
  position: relative;
  padding: 48px 32px;
}
.app-container > div {
  padding-bottom: 100px;
}
@media screen and (min-width: 640px) {
  .app-container::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1B1B51;
    z-index: -1;
    pointer-events: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/components/Layouts/Default/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACEA;EACE,sBAAA;EACA,gBDUa;ECTb,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;AAAF;AAEE;EACE,qBAAA;AAAJ;AAGE;EACE;IACE,WAAA;IACA,eAAA;IACA,MAAA;IACA,OAAA;IACA,WAAA;IACA,YAAA;IACA,yBDpBE;ICqBF,WAAA;IACA,oBAAA;EADJ;AACF","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../../variables.scss';\n\n.app-container {\n  background-color: #FFF;\n  max-width: $mobile-width;\n  width: 100vw;\n  min-height: 100dvh;\n  position: relative;\n  padding: $xl-space $lg-space;\n\n  & > div {\n    padding-bottom: 100px;\n  }\n\n  @media screen and (min-width: $mobile-width) {\n    &::after {\n      content: '';\n      position: fixed;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      background-color: $black;\n      z-index: -1;\n      pointer-events: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
