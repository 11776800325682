import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message } from 'antd';
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import './style.scss';
import { resetPasswordForEmail } from "api/supabaseApi";

function ForgotPassword() {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      // Enviar e-mail de recuperação de senha
      await resetPasswordForEmail(values.email);
      message.success('If there is an account linked to this email, we will send a link for recovery');
    } catch (error) {
      console.error('Error:', error.message);
      message.error(`Falha na atualização: ${error.message}`);
    }
    return navigate("/login");
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className="ForgotPassword">
      <div className="forgot-password-form">
        <Form
          name="forgot-password-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder="Your email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send Verification Link
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
