// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.Tasks .task-status-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #D7E2EF;
  top: 24px;
  left: 12px;
  border-radius: 50%;
}
.Tasks .task-status-dot.red {
  background-color: #F93D15;
}
.Tasks .task-status-dot.yellow {
  background-color: #FFC700;
}
.Tasks .task-status-dot.green {
  background-color: #2DD673;
}
.Tasks .task-status-dot.anticon {
  position: relative;
  top: -2px;
  left: 0;
  margin: 0 3px;
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/pages/Tasks/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACGE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBDHG;ECIH,SAAA;EACA,UAAA;EACA,kBAAA;AADJ;AAGI;EAAQ,yBDPN;ACON;AACI;EAAW,yBDNN;ACQT;AADI;EAAU,yBDRN;ACYR;AAFI;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,aAAA;AAIN","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../variables.scss';\n\n.Tasks {  \n  .task-status-dot {\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    background-color: $grey;\n    top: 24px;\n    left: 12px;\n    border-radius: 50%;\n\n    &.red { background-color: $red; }\n    &.yellow { background-color: $yellow; }\n    &.green { background-color: $green; }\n\n    &.anticon {\n      position: relative;\n      top: -2px;\n      left: 0;\n      margin: 0 3px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
