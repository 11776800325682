// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PROJECT VARIABLES */
.total-profit .total-profit-label {
  font-size: 0.9em;
  line-height: 1em;
}
.total-profit .total-profit-amount {
  font-weight: bold;
  font-size: 1.2em;
  color: #6A52EE;
}`, "",{"version":3,"sources":["webpack://./src/variables.scss","webpack://./src/pages/Machines/style.scss"],"names":[],"mappings":"AAAA,sBAAA;ACGE;EACE,gBAAA;EACA,gBAAA;AADJ;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,cDPK;ACMT","sourcesContent":["/* PROJECT VARIABLES */\n\n$black: #1B1B51;\n$purple: #6A52EE;\n$grey: #D7E2EF;\n$red: #F93D15;\n$green: #2DD673;\n$yellow: #FFC700;\n\n$xl-space: 48px;\n$lg-space: 32px;\n$md-space: 16px;\n$sm-space: 8px;\n\n$mobile-width: 640px;\n$tablet-width: 1024px;\n\n$lg-radius: $xl-space;\n$radius: $md-space;\n\n$transition: .2s ease-in-out;\n\n$font-family: 'Poppins';","@import '../../variables.scss';\n\n.total-profit {\n  .total-profit-label {\n    font-size: .9em;\n    line-height: 1em;\n  }\n  .total-profit-amount {\n    font-weight: bold;\n    font-size: 1.2em;\n    color: $purple;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
