// api/supabaseAPI.js
import supabase from './supabaseClient';
import moment from 'moment';

export async function loginUser(email, password) {
    let user, session, error;

    if (email && password) {
        // Tenta fazer login com e-mail e senha fornecidos
        const response = await supabase.auth.signInWithPassword({ email, password });
        if (!response.error) {
            ({ user, session, error } = response.data);
        }
        if (error) throw new Error(error.message);
    } else {
        // Assume que o usuário já está logado e tenta obter a sessão atual
        user = supabase.auth.user();
        session = supabase.auth.session();
        if (!user || !session) throw new Error("No active session found");
    }

    // Extrai os tokens da sessão
    const { access_token, refresh_token } = session;

    return { user, access_token, refresh_token };
}

export async function fetchProfile(userId) {
    const { data, error } = await supabase
        .from('profile')
        .select('id, name, role')
        .eq('user_id', userId)
        .single();
    if (error) throw new Error(error.message);
    return data;
}

export async function fetchCeo(profileId) {
    const { data, error } = await supabase
        .from('ceo')
        .select('*')
        .eq('profile_id', profileId)
        .single();
    if (error) throw new Error(error.message);
    return data;
}

export async function fetchCompany(ceoId) {
    const { data, error } = await supabase
        .from('company')
        .select('*')
        .eq('ceo_id', ceoId)
        .single();
    if (error) throw new Error(error.message);
    return data;
}

export async function updateProfile(ceoId, newName) {
    // Buscar o profile_id correspondente ao ceo_id
    const { data: ceoData, error: ceoError } = await supabase
        .from('ceo')
        .select('profile_id')
        .eq('id', ceoId)
        .single(); // Assume-se que cada ceo tem exatamente um profile_id

    if (ceoError) throw new Error(ceoError.message);
    if (!ceoData) throw new Error("Profile not found for the given CEO ID");
    // Agora, com o profile_id, podemos atualizar a tabela profile
    const { data, error } = await supabase
        .from('profile')
        .update({ name: newName })
        .eq('id', ceoData.profile_id);

    if (error) throw new Error(error.message);
    return data;
}

export async function updateCompany(ceoId, updatedData) {
    // Primeiro, buscar a company associada ao ceoId
    const { data: companyData, error: fetchError } = await supabase
        .from('company')
        .select('*')
        .eq('ceo_id', ceoId)
        .single();

    if (fetchError) throw new Error(fetchError.message);
    if (!companyData) throw new Error("Company not found for the given CEO ID");

    // Agora, atualizar a company com os dados fornecidos
    const { data, error } = await supabase
        .from('company')
        .update(updatedData)
        .eq('id', companyData.id);

    if (error) throw new Error(error.message);
    return data;
}

export async function updateUser(newEmail, newPassword) {
    const updates = {};
    if (newEmail) updates.email = newEmail;
    if (newPassword) updates.password = newPassword;

    const { data, error } = await supabase.auth.updateUser(updates);

    if (error) throw new Error(error.message);
    return data;
}

export async function logoutUser() {
    const { error } = await supabase.auth.signOut();
    if (error) throw new Error(error.message);

    // Retornar uma promessa para permitir ações adicionais após o logout
    return true;
}

export async function resetPasswordForEmail(email) {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
        throw new Error(error.message);
    }
    return data;
}

// supabaseApi.js

export async function fetchInitialData() {
    // Dados estáticos por enquanto
    return Promise.resolve({
        locations: [
            { id: '704300fd', companyId: '1', name: 'sma 3', address1: '136', address2: 'Ashford Circle', city: 'Dunwoody', state: 'GA', zipcode: '30338' },
            { id: 'b7628633', companyId: '1', name: 'SMA Demo', address1: '136', address2: 'Ashford Circle', city: 'Dunwoody', state: 'GA', zipcode: '30338' },
            { id: 's6s2912g', companyId: '1', name: 'Azuquita', address1: 'gj', address2: 'gh', city: 'gh', state: 'hs', zipcode: '123' }
        ],
        machines: [
            { id: '1', locationId: 's6s2912g', name: 'Alice10', nodeId: '1', valid: true },
            { id: '12345', locationId: '704300fd', name: 'Rks 1', nodeId: '1', valid: true },
            { id: 'uhby8u', locationId: 'b7628633', name: '9uu9', nodeId: '9', valid: true }
        ],
        tasks: [
            { id: '1', locationId: 's6s2912g', machineId: '1', userId: '4', date: '2024-05-22', title: 'Task 1', status: 'toDo', description: 'test description' },
            { id: '12345', locationId: '704300fd', machineId: '12345', userId: '5', date: '2024-04-27', title: 'Task 2', status: 'doing', description: 'test description' },
            { id: 'uhby8u', locationId: 'b7628633', machineId: 'uhby8u', userId: '6', date: '2024-02-14', title: 'Task 3', status: 'done', description: 'test description' }
        ],
        technicians: [
            { id: '1', companyId: '1', locationId: null, roleId: '1', username: 'test_ceo', email: 'test.ceo@test.com', name: 'Test CEO', password: 'Test123', valid: true },
            { id: '2', companyId: '1', locationId: 'b7628633', roleId: '2', username: 'test_manager', email: 'test.manager@test.com', name: 'Test Manager', password: 'Test123', valid: true },
            { id: '3', companyId: '1', locationId: '704300fd', roleId: '2', username: 'test_manager2', email: 'test.manager2@test.com', name: 'Test Manager 2', password: 'Test123', valid: true },
            { id: '4', companyId: '1', locationId: null, roleId: '3', username: 'test_technician', email: 'test.technician@test.com', name: 'Test Technician', password: 'Test123', valid: true },
            { id: '5', companyId: '1', locationId: null, roleId: '3', username: 'test_technician2', email: 'test.technician2@test.com', name: 'Test Technician 2', password: 'Test123', valid: true },
            { id: '6', companyId: '1', locationId: null, roleId: '3', username: 'test_technician3', email: 'test.technician3@test.com', name: 'Test Technician 3', password: 'Test123', valid: true },
        ],
        managers: [
            { id: '2', companyId: '1', locationId: 'b7628633', roleId: '2', username: 'test_manager', email: 'test.manager@test.com', name: 'Test Manager', password: 'Test123', valid: true },
            { id: '3', companyId: '1', locationId: '704300fd', roleId: '2', username: 'test_manager2', email: 'test.manager2@test.com', name: 'Test Manager 2', password: 'Test123', valid: true },
        ],
        companies: []
    });
}

export async function fetchDataLocation() {
    const { data, error } = await supabase
        .from('location')
        .select('id, company_id, name, address1, address2, city, state, zipcode, valid, location_code');

    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export async function updateLocationDB(locationId, updatedData) {
    const { data, error } = await supabase
        .from('location')
        .update(updatedData)
        .match({ id: locationId });

    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export async function createLocationDB(newLocationData) {
    const { data, error } = await supabase
        .from('location')
        .insert([newLocationData])
        .select();

    if (error) {
        throw new Error(error.message);
    }

    return data && data.length > 0 ? data[0] : undefined;
}

export async function deleteLocationDB(locationId) {
    const { data, error } = await supabase
        .from('location')
        .delete()
        .match({ id: locationId });

    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export async function fetchDataMachine() {
    const { data, error } = await supabase
        .from('machine')
        .select('*');

    if (error) {
        throw new Error(error.message);
    }
    return data;
}

export async function updateMachineDB(machineId, updatedData) {
    const { data, error } = await supabase
        .from('machine')
        .update(updatedData)
        .match({ id: machineId });

    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export async function createMachineDB(newMachineData) {
    const { data, error } = await supabase
        .from('machine')
        .insert([newMachineData])
        .select();

    if (error) {
        throw new Error(error.message);
    }

    return data && data.length > 0 ? data[0] : undefined;
}

export async function deleteMachineDB(machineId) {
    const { data, error } = await supabase
        .from('machine')
        .delete()
        .match({ id: machineId });

    if (error) {
        throw new Error(error.message);
    }

    return data;
}

// Nova função para buscar dados para gráficos
export async function fetchChartData(viewType, startDate, endDate, locationCodes) {
    let viewName = '';
    let startDateField;
    let endDateField;
    // Definir o nome da view com base no tipo fornecido
    switch (viewType) {
        case 'day':
            viewName = 'daily_machine_statistics';
            startDateField = 'date';
            endDateField = 'date';
            break;
        case 'week':
            viewName = 'weekly_machine_statistics';
            startDateField = 'week_start';
            endDateField = 'week_end';
            break;
        case 'month':
            viewName = 'monthly_machine_statistics';
            startDateField = 'month_start';
            endDateField = 'month_end';
            break;
        case 'year':
            viewName = 'yearly_machine_statistics';
            startDateField = 'year_start';
            endDateField = 'year_end';
            break;
        default:
            return [];
    }

    // Converter as datas para strings no formato 'YYYY-MM-DD'
    const startOfPeriod = moment(startDate).format('YYYY-MM-DD');
    const endOfPeriod = moment(endDate).format('YYYY-MM-DD');

    // Corrigir ordem dos filtros de data para garantir intervalos corretos
    const { data, error } = await supabase
        .from(viewName)
        .select("*")
        .in('device_code', locationCodes)
        .gte(startDateField, startOfPeriod)
        .lte(endDateField, endOfPeriod);

    if (error) {
        console.error('Error fetching data:', error);
        return [];
    }
    return data;
}

export async function fetchChartDataByMachine(viewType, startDate, endDate, locationCodes, node) {
    let viewName = '';
    let startDateField;
    let endDateField;
    // Definir o nome da view com base no tipo fornecido
    switch (viewType) {
        case 'day':
            viewName = 'daily_machine_statistics';
            startDateField = 'date';
            endDateField = 'date';
            break;
        case 'week':
            viewName = 'weekly_machine_statistics';
            startDateField = 'week_start';
            endDateField = 'week_end';
            break;
        case 'month':
            viewName = 'monthly_machine_statistics';
            startDateField = 'month_start';
            endDateField = 'month_end';
            break;
        case 'year':
            viewName = 'yearly_machine_statistics';
            startDateField = 'year_start';
            endDateField = 'year_end';
            break;
        default:
            return [];
    }

    // Converter as datas para strings no formato 'YYYY-MM-DD'
    const startOfPeriod = moment(startDate).format('YYYY-MM-DD');
    const endOfPeriod = moment(endDate).format('YYYY-MM-DD');

    // Corrigir ordem dos filtros de data para garantir intervalos corretos
    const { data, error } = await supabase
        .from(viewName)
        .select("*")
        .in('device_code', locationCodes)
        .eq('sending_node', node)
        .gte(startDateField, startOfPeriod)
        .lte(endDateField, endOfPeriod);

    if (error) {
        console.error('Error fetching data:', error);
        return [];
    }
    return data;
}

export async function fetchChartDataByLocation(viewType, startDate, endDate, locationId) {
    let viewName = '';
    let startDateField;
    let endDateField;
    // Definir o nome da view com base no tipo fornecido
    switch (viewType) {
        case 'day':
            viewName = 'daily_machine_statistics';
            startDateField = 'date';
            endDateField = 'date';
            break;
        case 'week':
            viewName = 'weekly_machine_statistics';
            startDateField = 'week_start';
            endDateField = 'week_end';
            break;
        case 'month':
            viewName = 'monthly_machine_statistics';
            startDateField = 'month_start';
            endDateField = 'month_end';
            break;
        case 'year':
            viewName = 'yearly_machine_statistics';
            startDateField = 'year_start';
            endDateField = 'year_end';
            break;
        default:
            return [];
    }

    // Converter as datas para strings no formato 'YYYY-MM-DD'
    const startOfPeriod = moment(startDate).format('YYYY-MM-DD');
    const endOfPeriod = moment(endDate).format('YYYY-MM-DD');

    // Corrigir ordem dos filtros de data para garantir intervalos corretos
    const { data, error } = await supabase
        .from(viewName)
        .select("*")
        .eq('device_code', locationId)
        .gte(startDateField, startOfPeriod)
        .lte(endDateField, endOfPeriod);

    if (error) {
        console.error('Error fetching data:', error);
        return [];
    }
    return data;
}

export async function fetchSingleDayData(date) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const viewName = 'daily_machine_statistics';

    const { data, error } = await supabase
        .from(viewName)
        .select("*")
        .eq('date', formattedDate);

    if (error) {
        console.error('Error fetching single day data:', error);
        return [];
    }

    return data;
}

export async function fetchIntervalData(viewType, startDate, endDate, locationCodes) {
    let viewName = '';
    let startDateField;
    let endDateField;

    switch (viewType) {
        case 'day':
            viewName = 'daily_machine_statistics';
            startDateField = 'date';
            endDateField = 'date';
            break;
        case 'week':
            viewName = 'weekly_machine_statistics';
            startDateField = 'week_start';
            endDateField = 'week_end';
            break;
        case 'month':
            viewName = 'monthly_machine_statistics';
            startDateField = 'month_start';
            endDateField = 'month_end';
            break;
        case 'year':
            viewName = 'yearly_machine_statistics';
            startDateField = 'year_start';
            endDateField = 'year_end';
            break;
        default:
            return [];
    }

    const startOfPeriod = moment(startDate).format('YYYY-MM-DD');
    const endOfPeriod = moment(endDate).format('YYYY-MM-DD');
    let dataDone;
    let errorDone;
    if (viewType === 'day') {
        const { data, error } = await supabase
            .from(viewName)
            .select("*")
            .in('device_code', locationCodes)
            .eq(startDateField, endOfPeriod);
        dataDone = data;
        errorDone = error;
    } else {
        const { data, error } = await supabase
            .from(viewName)
            .select("*")
            .in('device_code', locationCodes)
            .gte(startDateField, startOfPeriod)
            .lte(endDateField, endOfPeriod);
        dataDone = data;
        errorDone = error;
    }


    if (errorDone) {
        console.error('Error fetching data for ', viewType, ' between ', startOfPeriod, ' and ', endOfPeriod, ': ', errorDone);
        return [];
    }
    return dataDone;
}

export async function fetchIntervalDataByMachine(viewType, startDate, endDate, locationCodes, node) {
    let viewName = '';
    let startDateField;
    let endDateField;

    switch (viewType) {
        case 'day':
            viewName = 'daily_machine_statistics';
            startDateField = 'date';
            endDateField = 'date';
            break;
        case 'week':
            viewName = 'weekly_machine_statistics';
            startDateField = 'week_start';
            endDateField = 'week_end';
            break;
        case 'month':
            viewName = 'monthly_machine_statistics';
            startDateField = 'month_start';
            endDateField = 'month_end';
            break;
        case 'year':
            viewName = 'yearly_machine_statistics';
            startDateField = 'year_start';
            endDateField = 'year_end';
            break;
        default:
            return [];
    }

    const startOfPeriod = moment(startDate).format('YYYY-MM-DD');
    const endOfPeriod = moment(endDate).format('YYYY-MM-DD');
    let dataDone;
    let errorDone;
    if (viewType === 'day') {
        const { data, error } = await supabase
            .from(viewName)
            .select("*")
            .in('device_code', locationCodes)
            .eq('sending_node', node)
            .eq(startDateField, endOfPeriod);
        dataDone = data;
        errorDone = error;
    } else {
        const { data, error } = await supabase
            .from(viewName)
            .select("*")
            .in('device_code', locationCodes)
            .eq('sending_node', node)
            .gte(startDateField, startOfPeriod)
            .lte(endDateField, endOfPeriod);
        dataDone = data;
        errorDone = error;
    }


    if (errorDone) {
        console.error('Error fetching data for ', viewType, ' between ', startOfPeriod, ' and ', endOfPeriod, ': ', errorDone);
        return [];
    }

    return dataDone;
}

export async function fetchIntervalDataByLocation(viewType, startDate, endDate, locationId) {
    let viewName = '';
    let startDateField;
    let endDateField;

    switch (viewType) {
        case 'day':
            viewName = 'daily_machine_statistics';
            startDateField = 'date';
            endDateField = 'date';
            break;
        case 'week':
            viewName = 'weekly_machine_statistics';
            startDateField = 'week_start';
            endDateField = 'week_end';
            break;
        case 'month':
            viewName = 'monthly_machine_statistics';
            startDateField = 'month_start';
            endDateField = 'month_end';
            break;
        case 'year':
            viewName = 'yearly_machine_statistics';
            startDateField = 'year_start';
            endDateField = 'year_end';
            break;
        default:
            return [];
    }

    const startOfPeriod = moment(startDate).format('YYYY-MM-DD');
    const endOfPeriod = moment(endDate).format('YYYY-MM-DD');
    let dataDone;
    let errorDone;
    if (viewType === 'day') {
        const { data, error } = await supabase
            .from(viewName)
            .select("*")
            .eq('device_code', locationId)
            .eq(startDateField, endOfPeriod);
        dataDone = data;
        errorDone = error;
    } else {
        const { data, error } = await supabase
            .from(viewName)
            .select("*")
            .eq('device_code', locationId)
            .gte(startDateField, startOfPeriod)
            .lte(endDateField, endOfPeriod);
        dataDone = data;
        errorDone = error;
    }


    if (errorDone) {
        console.error('Error fetching data for ', viewType, ' between ', startOfPeriod, ' and ', endOfPeriod, ': ', errorDone);
        return [];
    }

    return dataDone;
}




